// import "./node_modules/minireset.css/minireset.min.css"
// import "./src/styles.css"
// require("prismjs/themes/prism-okaidia.css")

exports.onRouteUpdate = location => {
  if (location.location.hash) {
    setTimeout(() => {
      const selector = location.location.hash
      const el = document.querySelector(selector)
      if (el) {
        el.scrollIntoView({ behavior: "smooth" })
      }
    }, 500)
  }
}

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-copywriting-js": () => import("./../../../src/pages/services/copywriting.js" /* webpackChunkName: "component---src-pages-services-copywriting-js" */),
  "component---src-pages-services-logodesign-js": () => import("./../../../src/pages/services/logodesign.js" /* webpackChunkName: "component---src-pages-services-logodesign-js" */),
  "component---src-pages-services-photography-js": () => import("./../../../src/pages/services/photography.js" /* webpackChunkName: "component---src-pages-services-photography-js" */),
  "component---src-pages-services-websites-js": () => import("./../../../src/pages/services/websites.js" /* webpackChunkName: "component---src-pages-services-websites-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */)
}

